import React, { Component } from "react";
import { graphql } from "gatsby";
import { css } from "emotion";
import MQ from "../globals/mq";
import Responsive from "react-responsive";
import { keyframes } from "react-emotion";
import Img from "gatsby-image";
import ReactPlayer from "react-player";
import Layout from "../components/layout";
import Colors from "../globals/colors";
import NoCode from "../assets/nocode.js";
import Devolver from "../assets/devolver.js";
import ESRB from "../assets/esrb.js";
import LogoSprite from "../images/logo-sprites-vertical.png";

const rotate = keyframes({
  from: { transform: `rotate(0deg)` },
  to: { transform: `rotate(360deg)` },
});

const enter = keyframes({
  to: { backgroundPosition: `0 -47.87rem` },
});

const navigation = css({
  position: `relative`,
  width: `100%`,
  display: `flex`,
  alignSelf: `flex-start`,
  alignItems: `center`,
  justifyContent: `space-between`,
  padding: `2rem 3rem 0`,
  color: Colors.white,
  fontWeight: `bold`,
  letterSpacing: `1px`,
  textTransform: `uppercase`,
});

const navItem = css({
  display: `inline-block`,
  fontSize: `0.9rem`,
  padding: `0 1.2rem`,
  color: Colors.white,
  letterSpacing: `1px`,
  "&:first-child": {
    paddingLeft: 0,
  },
});

const link = css({
  cursor: `pointer`,
  transition: `color 0.3s ease`,
  "&:hover": {
    color: Colors.red,
  },
});

const nav = css({
  display: `flex`,
  alignItems: `center`,
  marginBottom: `2rem`,
  a: [navItem, link],
  span: navItem,
});

const close = css({
  fontSize: `2rem !important`,
});

const when = css({
  fontSize: `0.9rem !important`,
  color: `${Colors.blue} !important`,
});

const navLogo = css({
  width: `20rem`,
  height: `2.66rem`,
  paddingRight: `2.4rem`,
  backgroundSize: `100% 1900%`,
  backgroundImage: `url(${LogoSprite})`,
  backgroundPosition: `0 2.66rem`,
  animation: `${enter} 1.9s steps(19)`,
});

const footer = css({
  display: `flex`,
  flexDirection: `column`,
  width: `58%`,
  margin: `0 auto`,
  paddingBottom: `3rem`,
});

const ratings = css({
  position: `absolute`,
  padding: `3rem`,
  width: `100%`,
  height: `100%`,
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-end`,
  justifyContent: `flex-start`,
  margin: `0 auto`,
  zIndex: 1,
  pointerEvents: `none`,
});

const actions = css({
  position: `absolute`,
  padding: `3rem`,
  width: `100%`,
  height: `100%`,
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  justifyContent: `center`,
  margin: `0 auto`,
  zIndex: 1,
  pointerEvents: `none`,
});

const storeButton = css({
  cursor: `pointer`,
  fontSize: "0.65rem",
  fontWeight: "600",
  textAlign: `center`,
  backgroundColor: Colors.white,
  color: Colors.black,
  padding: `1rem 2rem`,
  margin: `0 1rem`,
  transition: `all 0.3s ease`,
  "&:hover": {
    backgroundColor: Colors.red,
    color: Colors.white,
  },
  pointerEvents: `all`,
});

const Landscape = (props) => (
  <Responsive {...props} orientation={"landscape"} />
);
const Portrait = (props) => <Responsive {...props} orientation={"portrait"} />;

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
    this.onTrailerClickHandler = this.onTrailerClickHandler.bind(this);
    this.onCloseClickHandler = this.onCloseClickHandler.bind(this);
    this.onTrailerEndedHandler = this.onTrailerEndedHandler.bind(this);
    this.onStoreButtonClickHandler = this.onStoreButtonClickHandler.bind(this);
    this.ref = this.ref.bind(this);
  }
  onTrailerClickHandler() {
    this.setState({ playing: true });
  }
  onCloseClickHandler() {
    this.player.seekTo(0);
    this.setState({ playing: false });
  }
  onTrailerEndedHandler() {
    this.setState({ playing: false });
  }
  ref(player) {
    this.player = player;
  }
  onStoreButtonClickHandler(label) {
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "Lead", { content_category: label });
        }
      }
    }
  }
  render() {
    const { playing } = this.state;
    return (
      <Layout>
        <Responsive orientation={"portrait"}>
          {(match) => {
            if (match) {
              return (
                <Img
                  backgroundColor={`${Colors.black}`}
                  style={{
                    width: `100%`,
                    height: `100%`,
                    position: `absolute`,
                  }}
                  fluid={
                    this.props.data.keyartPortraitImage.childImageSharp.fluid
                  }
                />
              );
            } else {
              return (
                <Img
                  backgroundColor={`${Colors.black}`}
                  style={{
                    width: `100%`,
                    height: `100%`,
                    position: `absolute`,
                  }}
                  fluid={
                    this.props.data.keyartLandscapeImage.childImageSharp.fluid
                  }
                />
              );
            }
          }}
        </Responsive>
        <div
          style={{
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            backgroundColor: `${Colors.black}`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignCenter: `center`,
            opacity: playing ? `1` : `0`,
          }}
        >
          <ReactPlayer
            ref={this.ref}
            className={css({
              position: `absolute`,
              top: 0,
              left: 0,
            })}
            url="https://www.youtube.com/watch?v=gKa6eKZBv3U"
            width="100%"
            height="100%"
            volume={0.4}
            controls
            playing={playing}
            onEnded={this.onTrailerEndedHandler}
            config={{
              youtube: {
                playerVars: { modestbranding: 1, fs: 1 },
              },
            }}
          />
        </div>
        <nav
          className={css`
            ${navigation} & {
              @media only screen and (orientation: portrait) {
                justify-content: center;
              }
              ${MQ.ipSE(
                css`
                  flex-wrap: wrap;
                `
              )};
              ${MQ.ip68(
                css`
                  flex-wrap: wrap;
                `
              )};
              ${MQ.ip8U(
                css`
                  flex-wrap: wrap;
                `
              )};
              ${MQ.ipXX(
                css`
                  flex-wrap: wrap;
                `
              )};
            }
          `}
        >
          <div className={nav}>
            <Landscape>
              <div className={navLogo} />
            </Landscape>
            <span className={when}>Available Now</span>
          </div>
          <div className={nav}>
            <span
              onClick={this.onCloseClickHandler}
              style={{ display: playing ? `block` : `none` }}
              className={`${navItem} ${link} ${close}`}
            >
              &times;
            </span>
            <span
              onClick={this.onTrailerClickHandler}
              style={{ display: playing ? `none` : `block` }}
              className={`${navItem} ${link}`}
            >
              Trailer
            </span>
            <a href="https://store.steampowered.com/app/906100/Observation/">
              STEAM
            </a>
            <a href="https://www.epicgames.com/store/en-US/product/observation/home">
              EPIC
            </a>
            <a href="https://www.playstation.com/en-us/games/observation-ps4/">
              PS4
            </a>
            <a href="https://www.microsoft.com/en-us/p/observation/9pblh1322gx0">
              XBOX
            </a>
            <a href="https://twitter.com/_nocode">
              <NoCode />
            </a>
            <a href="https://twitter.com/devolverdigital">
              <Devolver />
            </a>
          </div>
        </nav>
        <div className={ratings} style={{ display: playing ? `none` : `flex` }}>
          <Landscape>
            <ESRB
              className={css({
                zIndex: `0`,
                height: `3.5rem`,
              })}
            />
          </Landscape>
        </div>
        <div className={actions} style={{ display: playing ? `none` : `flex` }}>
          <a
            // target="_blank"
            // rel="noopener noreferrer"
            href="https://store.steampowered.com/app/906100/Observation/"
            className={storeButton}
            onClick={this.onStoreButtonClickHandler("steam-store")}
          >
            BUY ON STEAM
          </a>
          <a
            // target="_blank"
            // rel="noopener noreferrer"
            href="https://www.epicgames.com/store/en-US/product/observation/home"
            className={storeButton}
            onClick={this.onStoreButtonClickHandler("epic-games-store")}
          >
            BUY ON EPIC STORE
          </a>
          <a
            href="https://www.playstation.com/en-us/games/observation-ps4/"
            className={storeButton}
            onClick={this.onStoreButtonClickHandler("playstation-store")}
          >
            BUY ON PS STORE
          </a>
          <a
            href="https://www.microsoft.com/en-us/p/observation/9pblh1322gx0"
            className={storeButton}
            onClick={this.onStoreButtonClickHandler("xbox-store")}
          >
            BUY ON XBOX
          </a>
        </div>
        <div
          className={css`
            ${footer} & {
              @media only screen and (orientation: portrait) {
                width: 100% !important;
              }
              ${MQ.ipSE(
                css`
                  width: 64%;
                  padding: 0 2rem 1rem;
                `
              )};
              ${MQ.ip68(
                css`
                  padding: 0 3rem 3rem;
                `
              )};
              ${MQ.ip8U(
                css`
                  padding: 0 3rem 3rem;
                `
              )};
              ${MQ.ipXX(
                css`
                  padding: 0 3rem 3rem;
                `
              )};
            }
            opacity: ${playing ? 0 : 1};
          `}
        >
          <Img
            className={css({
              alignSelf: `flex-end`,
              width: `7rem`,
              height: `7rem`,
              marginBottom: `4rem`,
              animation: `${rotate} 240s linear infinite`,
              transformOrigin: `60% 55%`,
            })}
            fluid={this.props.data.spaceStationImage.childImageSharp.fluid}
          />
          <Portrait>
            <div
              className={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
              `}
            >
              <Img
                className={css({
                  width: `45rem`,
                  height: `5.975rem`,
                })}
                fluid={this.props.data.logoImage.childImageSharp.fluid}
              />
              <ESRB
                className={css({
                  zIndex: `0`,
                  marginTop: `2rem`,
                  height: `5rem`,
                })}
              />
            </div>
          </Portrait>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query {
    keyartLandscapeImage: file(relativePath: { regex: "/landscape/" }) {
      childImageSharp {
        fluid(maxWidth: 5120) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    keyartPortraitImage: file(relativePath: { regex: "/portrait/" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    spaceStationImage: file(relativePath: { regex: "/space-station/" }) {
      childImageSharp {
        fluid(maxWidth: 760) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoImage: file(relativePath: { regex: "/observation-logo/" }) {
      childImageSharp {
        fluid(maxWidth: 1930) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
