import { css } from 'emotion'

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  mb11: 1400,
  mb13: 1600,
  mb15: 1900,
  im27: 2500,
  im54: 5120,
  ipSE: 'only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2)',
  ip68: 'only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)',
  ip8U: 'only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)',
  ipXX: 'only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)'
}

const mq = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    let prefix = typeof breakpoints[label] === 'string' ? '' : '(min-width:'
    let suffix = typeof breakpoints[label] === 'string' ? '' : 'px)'
    accumulator[label] = cls => css`
      @media ${prefix + breakpoints[label] + suffix} {
        ${cls};
      }
    `
    return accumulator
  }, {}
)

export default mq
