import DecimaMonoOTF from './decima-mono.otf'
import DecimaMonoWOFF from './decima-mono.woff'
import DecimaMonoWOFF2 from './decima-mono.woff2'
import DecimaNovaOTF from './decima-nova-pro-regular.otf'
import DecimaNovaWOFF from './decima-nova-pro-regular.woff'
import DecimaNovaWOFF2 from './decima-nova-pro-regular.woff2'

export default {
  DecimaMonoOTF,
  DecimaMonoWOFF,
  DecimaMonoWOFF2,
  DecimaNovaOTF,
  DecimaNovaWOFF,
  DecimaNovaWOFF2
}
