import React, {Fragment} from "react";
import PropTypes from "prop-types";
import emotionNormalize from "emotion-normalize";
import {css, injectGlobal} from "emotion";
import Helmet from "react-helmet";
import {StaticQuery, graphql} from "gatsby";
import Fonts from "../fonts";
import Colors from "../globals/colors";
import MQ from "../globals/mq";

injectGlobal`
  @font-face {
    font-family:'DecimaMono';
    src:url(${Fonts.DecimaMonoWOFF2}) format('woff2'),url(${Fonts.DecimaMonoWOFF}) format('woff'),url(${Fonts.DecimaMonoOTF}) format('opentype');
    font-style:normal;font-weight:400;
  }
  @font-face {
    font-family:'DecimaNova';
    src:url(${Fonts.DecimaNovaWOFF2}) format('woff2'),url(${Fonts.DecimaNovaWOFF}) format('woff'),url(${Fonts.DecimaNovaOTF}) format('opentype');
    font-style:normal;font-weight:400;
  }
`;

injectGlobal`
  ${emotionNormalize}
  *, *::after, *::before {
    box-sizing: border-box;
     -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  .osano-cm-window__widget > svg > path:nth-of-type(1) {
    stroke: rgba(255,255,255,1) !important;
    fill-opacity: 0;
  }

  .osano-cm-window__widget > svg > path:nth-of-type(2) {
    stroke: rgba(255,255,255,0) !important;
    fill-opacity: 0;
  }

  .osano-cm-window__widget > svg > g {
    fill: rgba(255,255,255,1) !important;
  }

  .osano-cm-info {
    text-transform: none !important;
    box-shadow: 0 0 0 2px #333 !important;
  }

  .osano-cm-description p {
    font-size: 12px !important;
  }

  .osano-cm-drawer-toggle .osano-cm-label {
    font-size: 14px !important;
  }

  .osano-cm-header {
    font-size: 16px !important;
  }

  .osano-cm-message {
    text-transform: none !important;
    font-size: 16px !important;
  }

  html {
    ${MQ.sm(
      css`
        font-size: 8px;
      `,
    )}
    ${MQ.md(
      css`
        font-size: 10px;
      `,
    )}
    ${MQ.lg(
      css`
        font-size: 12px;
      `,
    )}
    ${MQ.xl(
      css`
        font-size: 14px;
      `,
    )}
    ${MQ.mb11(
      css`
        font-size: 16px;
      `,
    )}
    ${MQ.mb13(
      css`
        font-size: 18px;
      `,
    )}
    ${MQ.mb15(
      css`
        font-size: 18px;
      `,
    )}
    ${MQ.im27(
      css`
        font-size: 24px;
      `,
    )}
    ${MQ.im54(
      css`
        font-size: 48px;
      `,
    )}
    ${MQ.ipSE(
      css`
        font-size: 7px;
      `,
    )}
    ${MQ.ip68(
      css`
        font-size: 8px;
      `,
    )}
    ${MQ.ip8U(
      css`
        font-size: 8px;
      `,
    )}
    ${MQ.ipXX(
      css`
        font-size: 8px;
      `,
    )}
    background-color: ${Colors.black};
  }

  html, body {
    font-family: 'DecimaNova', sans-serif;
  }

  a {
    color: ${Colors.white};
    text-decoration: none;
  }

  .fill-current {
    fill: currentColor;
  }
`;

const container = css({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `space-between`,
  width: `100%`,
  height: `100vh`,
});

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteName
            siteUrl
            siteDescription
            facebookImage
            twitterImage
            twitterCard
            twitterSite
          }
        }
      }
    `}
    render={(data) => (
      <Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {name: "description", content: data.site.siteMetadata.description},
            {name: "keywords", content: data.site.siteMetadata.keywords},
            {name: "author", content: data.site.siteMetadata.author},
            {property: "og:title", content: data.site.siteMetadata.title},
            {
              property: "og:site_name",
              content: data.site.siteMetadata.siteName,
            },
            {property: "og:url", content: data.site.siteMetadata.siteUrl},
            {
              property: "og:description",
              content: data.site.siteMetadata.siteDescription,
            },
            {
              property: "og:image",
              content: data.site.siteMetadata.facebookImage,
            },
            {
              property: "twitter:card",
              content: data.site.siteMetadata.twitterCard,
            },
            {
              property: "twitter:site",
              content: data.site.siteMetadata.twitterSite,
            },
            {property: "twitter:title", content: data.site.siteMetadata.title},
            {
              property: "twitter:description",
              content: data.site.siteMetadata.siteDescription,
            },
            {
              property: "twitter:image",
              content: data.site.siteMetadata.twitterImage,
            },
          ]}
        >
          <script src="https://cmp.osano.com/169lamSiR0Q8x1aw3/42096c13-b02f-4791-997f-a9e9c8490712/osano.js"></script>
          <html lang="en" />
        </Helmet>
        <main className={container}>{children}</main>
      </Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
